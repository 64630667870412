.react-kanban-board {
  height: 100%;
}
.react-kanban-board > div {
  height: 100%;
}
.react-kanban-board > div > div {
  width: 400px;
  background: #f0eefa;
  height: calc(100% - 25px) !important;
}
.react-kanban-board > div > div > div:last-child {
  overflow: auto;
  height: calc(100% - 55px) !important;
}
.react-kanban-board > div > div > div:nth-child(2) > div > div {
  width: 100%;
}