#root, body {
	background-color: #f5f6f8 !important;
}
.cursor-pointer {
  cursor: pointer;
}
.MuiAppBar-root {
  color: rgba(255,255,255,.6) !important;
  background-color: #1b334c !important;
}
.MuiListItemIcon-root {
  min-width: 45px !important;
}
.header-user-menu {
  height: 60px;
  border-radius: 0;
  /*background-color: #1b334c;*/
  /*border-color: #1b334c;*/
  /*color: rgba(255,255,255,.6) !important;*/
}
.btn.focus {
  box-shadow: none !important;
}
.nav-user-dropdown {
  box-shadow: 0 4px 6px 0 hsla(0, 0%, 0%, 0.3) !important;
  border-radius: 3px !important;
}
.nav-user-dropdown button,
label {
  color: #6c757d;
}
.default-font {
  color: #6c757d !important;
}
.header-logo {
  font-family: "Brush Script MT", "Brush Script Std", cursive;
  font-size: 42px;
  font-weight: bold;
  color: hsl(225, 0%, 100%);
  width: 225px;
  padding-left: 7px;
}
.nav-toggle > span > svg {
  width: 1.2em !important;
  height: 1.2em !important;
}
.sidebar {
  color: #6e768e !important;
}
.MuiDrawer-paperAnchorDockedLeft {
  border-right: none !important;
}
.header-title {
  color: #323a46 !important;
  padding-left: 2px;;
}
.menu-item-selected {
  color: #00acc1 !important;
}
.menu-item-selected svg {
  color: #00acc1 !important;
}
.makeStyles-drawerPaperClose-10 {
  width: 60px !important;
}
.btn {
  font-size: inherit !important;
}
.btn-icon {
  width: 17px !important;
  height: 17px !important;
  padding-bottom: 2px !important;
}
.modal {
  z-index: 9999 !important;
}
.vertical-center-container {
  height: 100%;
  position: relative;
}
.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.modal-header {
	color: #fff;
	background-color: #1b334c;
	border-radius: 1px !important;
	padding: 12px 24px !important;
}
.modal-header span {
	font-weight: bold;
	font-size: 1rem !important;
}
.form-group {
	margin-bottom: 0.7rem !important;
}
.react-datepicker-wrapper {
	width: 100%;
}
.MuiFormControl-marginDense {
	margin-top: 0 !important;
}
.georgia {
	font-family: georgia !important;
}
.rdw-option-wrapper {
	padding: 4px;
	min-width: 20px !important;
	height: 18px !important;
	margin: 0 -1px;
}
.rdw-dropdown-wrapper {
	height: 26px;
	margin: 0 -1px;
}
.rdw-inline-wrapper,
.rdw-block-wrapper,
.rdw-fontsize-wrapper,
.rdw-fontfamily-wrapper,
.rdw-list-wrapper,
.rdw-text-align-wrapper,
.rdw-colorpicker-wrapper,
.rdw-link-wrapper,
.rdw-embedded-wrapper,
.rdw-emoji-wrapper,
.rdw-image-wrapper,
.rdw-remove-wrapper,
.rdw-history-wrapper {
	margin-bottom: 0 !important;
}
.rdw-history-wrapper {
	padding: 2px !important;
}
.rdw-option-wrapper > img {
	width: 12px;
	height: 12px;
}
.rdw-inline-wrapper > .rdw-option-wrapper:nth-child(1) > img {
	width: 9px;
	height: 9px;
}
.rdw-dropdown-selectedtext,
.rdw-dropdown-optionwrapper > li {
	font-size: 12px !important;
}
.rdw-editor-toolbar {
	border: none;
	border-bottom: 1px solid #F1F1F1;
	padding: 6px 5px 6px;
}
.public-DraftStyleDefault-block {
	margin-top: 0 !important;
	margin-bottom: 5px !important;
}
.editorClassName {
	padding: 0 7px;
	min-height: 100px;
	max-height: 110px;
	overflow-y:auto;
}
.upload-file-list-title {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	font-size: 11px;
	color: #737373;
	padding: 7px !important;
}
.upload-file-list-action {
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	padding-top: 4px;
	padding-right: 7px;
}
.MuiDrawer-paper {
	overflow: hidden !important;
}
.board-container {
	height: calc(100vh - 65px) !important;
}
.board-card {
	border: 1px solid #e6e6e6;
	box-shadow: none !important;
}
.custom-card-border {
	border: 1px solid #e6e6e6;
	box-shadow: none !important;
}
.board-card > div > div > .MuiCardHeader-title {
	line-height: 0.8 !important;
	color: #343a40;
}
.board-card-header {
	color: #343a40;
}
.board-card-header:hover {
	text-decoration: none;
	color: #008799;
}
.board-card-content {
	color: #6c757d;
}
.dUlnjw {
	width: 100%;
	height: calc(100vh - 133px) !important;
	padding: 0 !important;
}
.hLIAGC {
	height: calc(100vh - 160px) !important;
}
.dUlnjw::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3) !important;
	border-radius: 10px !important;
	background-color: #F5F5F5 !important;
}
.dUlnjw::-webkit-scrollbar
{
	height: 8px !important;
	background-color: #F5F5F5 !important;
}
.dUlnjw::-webkit-scrollbar-thumb
{
	border-radius: 10px !important;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3) !important;
	background-color: #38414a5c !important;
}
.jhZEpQ {
	height: calc(100vh - 245px) !important;
	overflow-y: auto !important;
	padding: 0 10px !important;
	margin-top: 5px !important;
}
.jhZEpQ::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3) !important;
	border-radius: 10px !important;
	background-color: transparent !important;
}
.jhZEpQ::-webkit-scrollbar
{
	width: 7px !important;
	background-color: transparent !important;
}
.jhZEpQ::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3) !important;
	background-color: #38414a5c !important;
}
.kPrVeO {
	width: 99% !important;
	background-color: #F0EEFA !important;
	width: 360px !important;
	margin-right: 18px !important;
	padding: 3px !important;
	padding-bottom: 0 !important;
	border-radius: 4px !important;
}
/* .kPrVeO > div:nth-child(1) {
	border-bottom: 1px solid #f2f2f2;
} */
.btbMnr {
	width: 100%;
}
.font-weight-bold {
	font-weight: 600 !important;
}
html, body, div, button, input, select, a {
	outline: none !important;
}

.mb-0 {
	margin-bottom: 0px !important;
}